// require('./src/styles/global.css')

// exports.shouldUpdateScroll = ({
//     routerProps: { location },
// }) => {
//     document.querySelectorAll('a[href^="#"]').forEach(anchor => {
//         anchor.addEventListener('click', function (e) {
//             e.preventDefault();

//             document.querySelector(this.getAttribute('href')).scrollIntoView({
//                 behavior: 'smooth'
//             });
//         });
//     });

//     if (location.hash) {
//         let hashElem = document.querySelector(location.hash)
//         console.log(hashElem.getBoundingClientRect().top)
//     }
// }

// function scrollTo() {
//     if (location.hash) {
//         let hashElem = document.querySelector(location.hash)
//         console.log(hashElem);
//         let leftCol = document.querySelector('#column__left')
//         let rightCol = document.querySelector('#column__right')

//         let left = document.querySelector(`#column__left ${location.hash}`)
//         let right = document.querySelector(`#column__right ${location.hash}`)

//         function getCoords(elem) {
//             let box = elem.getBoundingClientRect();
//             return box.top;
//         }

//         if (left) {
//             setTimeout(() => {
//                 const cordTop = getCoords(hashElem);
//                 leftCol.scrollTo({
//                     top: cordTop,
//                     behavior: 'smooth',
//                 })
//             }, 500)
//         } else {
//             console.log('else')
//             setTimeout(() => {
//                 let hashElem = document.querySelector(location.hash)
//                 console.log(hashElem)
//                 const cordTop = getCoords(hashElem);
//                 console.log(cordTop)
//                 rightCol.scrollTo({
//                     top: cordTop,
//                     behavior: 'smooth',
//                 })
//             }, 3000)
//         }
//     }
// }
// if (document.readyState == 'loading') {
//     document.addEventListener('DOMContentLoaded', () => { scrollTo() });
// } else {
//     setTimeout(() => {

//         scrollTo();
//     }, 1000)
// }
// return false
// }

// exports.onRouteUpdate = ({ location, prevLocation }) => {
//     console.log('from', location.pathname)
//     console.log('to', prevLocation ? prevLocation.pathname : null)
//     console.log('onRouteUpdate', location, location.hash)
//     // TODO: probably keep only window since it will be the default ctx of exec
//     if (window) {
//         window.scrollTo({ top: 0, left: 0 })
//     } else {
//         function getGlobalObject() {
//             return Function("return this")()
//         }
//         getGlobalObject().scrollTo({ top: 0, left: 0 })
//     }
//     if (true) {
//         let leftCol = document.querySelector("#column__left")
//         let rightCol = document.querySelector("#column__right")
//         if (leftCol && rightCol) {
//             leftCol.scrollTo({ top: 0, left: 0 })
//             rightCol.scrollTo({ top: 0, left: 0 })
//         }
//     }
// }

if (window) {
    window.onload = function () {

        const favicon = document.querySelector('link[rel="icon"]')
        console.log({ favicon })

        const faviconSize = 16;

        const canvas = document.createElement('canvas');
        canvas.width = faviconSize;
        canvas.height = faviconSize;

        const context = canvas.getContext('2d');
        const img = document.createElement('img');
        img.src = favicon.href;
        
        const arr = ['a','l','e','c','d','e','b','u','s','s','c','h','è','r','e',];
        const possible = arr.length;
        
        setInterval(() => {
            context.drawImage(img, 0, 0, faviconSize, faviconSize);
            context.font = 'bold 11px Arial';
            context.textAlign = "center";
            context.textBaseline = "middle";
            context.fillStyle = 'white';
            // 1. minuscules
            context.fillText(arr[Math.floor(Math.random() * possible)], canvas.width - faviconSize / 2, faviconSize / 2);
            // 2. majuscules (si vous voulez majuscules rajouter '//' a la ligne 120 (celle au dessus de celle ci) 
            // et enlever les '//' au debut de la ligne de la suivate (la ligne 123)
            // context.fillText(arr[Math.floor(Math.random() * possible)].toLocaleUpperCase(), canvas.width - faviconSize / 2, faviconSize / 2);
            favicon.href = canvas.toDataURL('image/png');
        }, 3000)

    };
}
// if (window) {
// console.log('window')
// if (window.innerWidth > 400) {

// setTimeout(() => {
//     const figures = document.querySelectorAll('figure.image');
//     // console.log(figures)
//     figures.forEach((figure) => {
//         // console.log(figure)
//         console.log(figure)
//         figure.addEventListener('click', function () {
//             // // styles
//             // figure.style.height = '100vh';
//             // figure.style.width = '100vw';
//             // figure.style.position = 'fixed';
//             // figure.style.top = 0;
//             // figure.style.left = 0;
//             // const [img] = this.children
//             // img.style.height = '90vh';
//             // // styles
//             console.log(this)

//             if (this.className === 'image') {
//                 this.className = 'image fullscreen'
//                 this.children[0].className = 'img-fullscreen'
//             } else {
//                 this.className = 'image'
//                 this.children[0].className = ''
//             }
//         })
//     })
// }, 1500)
// }
// const imgs = document.querySelectorAll('figure')

// if (document.readyState === 'complete') {
//     console.log('lol')
// }

// document.addEventListener("DOMContentLoaded", () => {
//     const imgs = document.querySelectorAll('figure.image');
//     console.log(imgs)
// });
// function work() {
//     const imgs = document.querySelectorAll('figure.image');
//     console.log(imgs)
// }

// if (document.readyState == 'loading') {
//     document.addEventListener('DOMContentLoaded', work);
// } else {
//     work();
// }
// if (document.readyState == 'loading') {
//     document.addEventListener('DOMContentLoaded', () => {
//         const imgs = document.querySelectorAll('figure')
//         console.log(imgs)
//     });
// } else {
//     setTimeout(() => {

//         const imgs = document.querySelectorAll('figure')
//         console.log(imgs)
//     }, 1000)
// }
// }

// document.querySelectorAll('oembed[url]').forEach(element => {
//     iframely.load(element, element.attributes.url.value);
// });

// const isBrowser = typeof window !== `undefined`

// if (isBrowser) {
//     setTimeout(() => {
//         console.log(location.hash)
//     }, 2000)
// }
